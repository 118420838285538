<template>
  <div class="setup">
    <div class="setup_heard">
      <div>
        <div class="setud-div">
          <p style="font-weight: bold">支付设置</p>
          <div class="setud-div-01">
            <p>是否开启</p>
            <p>操作</p>
          </div>
        </div>
        <div>
          <!-- <span style="color:#333333;margin-left:50px;">通知渠道</span> -->
          <div
            v-for="(item, index) in templateArr"
            :key="index"
            class="setup_content"
          >
            <div style="display: flex">
              <img style="margin-left: 20px" :src="item.iopon" alt />
              <div class="setup_content_left">
                <span class="rightspan">{{ item.message }}</span>
                <a
                  :href="item.url"
                  :class="{ leftsaa: index == 4 }"
                  target="_blank"
                  v-if="index != templateArr.length - 1"
                  class="leftspan"
                  >立即申请</a
                >
                <p class="bottomp">{{ item.botttext }}</p>
              </div>
            </div>
            <div class="div-kai">
              <!-- @change="kaibtn($event, item, index)" -->
              <el-switch
                @change="kaibtn($event, item, index)"
                v-model="item.value"
                :active-value="1"
                :disabled="!hasPerm(['setting.pay_service.update'])"
              ></el-switch>
              <el-button
                @click="editbut(item.path, item.value)"
                v-if="item.permission ? hasPerm(item.permission) : true"
                >编辑</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";

export default {
  data() {
    return {
      value: null,
      arrange: [
        {
          message: "支付宝支付",
          botttext:
            "全球领先的独立第三方支付平台,致力于为广大用户提供安全快速的电子支付/网上支付/手机支付体验",
          value: 0,
          iopon: require("@/assets/icon/group_17_back.svg"),
          url: "https://open.alipay.com/module/webApp",
          type: "pay.alipay.open",
          path: "/setup/payment/alipay",
          permission: ["setting.alipay_pay.update"],
        },
        {
          message: "微信支付",
          botttext: "提供专业、快速、低成本的短信平台发送和短",
          iopon: require("@/assets/icon/group_15_back_2.svg"),
          value: 0,
          url: "https://pay.weixin.qq.com/",
          type: "pay.wechat.open",
          path: "/setup/payment/wechat",
          permission: ["setting.wechat_pay.update"],
        },
        // {
        //   message: "银联企业网银支付",
        //   botttext: "银联在线支付B2B支付平台以商业银行网银为基础，为B2B电子商务提供全面的网上支付及清算对账服务。",
        //   value: 0,
        //   iopon: require("@/assets/icon/group_16_back.svg"),
        //   type: "pay.union.btb_open",
        //   path: "/setup/payment/enterprise",
        //   permission:['setting.union_pay_btb.update']
        // },
        // {
        //   message: "银联网关支付",
        //   botttext: "网关支付产品主要适用于持卡人在商户网站B2C购物支付场景，持卡人通过点击银联在线支付图标，并在银联在线支付网关完成支付信息录入，最终完成支付。",
        //   value: 0,
        //   iopon: require("@/assets/icon/group_15_back.svg"),
        //   type: "pay.union.btc_open",
        //   path: "/setup/payment/network",
        //   permission:['setting.union_pay_btc.update'],

        // },
        {
          message: "线下转账支付",
          botttext: "满足商家在各类交易场景中快速收款的需求",
          value: 0,
          iopon: require("@/assets/icon/mail.svg"),
          type: "pay.offline.open",
          path: "/setup/payment/offline",
          permission: ["setting.offline_pay.update"],
        },
      ],
      templateArr: [],
    };
  },
  mounted() {
    this.keyninit();
  },
  methods: {
    kaibtn(e, item, index) {
      // 获取所有的状态
      const result = this.templateArr.reduce((pre, item) => {
        pre[item.type] = item.value ? 1 : 0;
        return pre;
      }, {});
      // 修改状态
      this.$put(this.$apis.switchTypesApi, result).then((res) => {
        if (res.code == 200) {
          Message.success(item.value ? "开启成功！" : "关闭成功！");
          // 刷新状态
          this.keyninit();
        } else {
          Message.success(res.message);
        }
      });
    },
    // 初始化获取第三方按钮显示
    keyninit() {
      this.$get(this.$apis.switchTypesApi).then((res) => {
        for (const key in res.data) {
          let index = this.arrange.findIndex((item) => item.type == key);
          if (index >= 0) {
            this.$nextTick(() => {
              this.$set(this.arrange[index], "value", res.data[key]);
              this.$set(this, "templateArr", this.arrange);
            });
          }
        }
      });
    },
    editbut(path, value) {
      this.$router.push({ path, query: { type: value } });
    },
  },
};
</script>

<style lang="less" scoped>
.setup {
  // position: relative;
  // .backdrop{
  //   width: 100%;
  //   height: 50px;
  //   background: #F9F9FC;
  //   position: absolute;
  // }
  .setup_heard {
    // display: flex;
    // justify-content: space-between;
    .setud-div {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      height: 50px;
      background: #f9f9fc;

      p {
        font-weight: 800;
        margin-left: 20px;
      }

      .setud-div-01 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        min-width: 310px;
        max-width: 400px;

        p {
          font-weight: bold;
          margin-right: 60px;
        }
      }

      p {
        // margin-left: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0px;
      }
    }

    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
    background: #fff;
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;

    .setup_content {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      height: 70px;
      border-bottom: 1px solid #f2f2f2;

      .div-kai {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        min-width: 280px;
        max-width: 370px;
        // flex-grow: 0;

        .el-switch {
          margin-top: -25px;
        }

        .el-button {
          width: 88px;
          height: 38px;
          margin-top: -30px;
          margin-right: 30px;
        }
      }

      .leftsaa {
        display: none;
      }

      .setup_content_left {
        min-width: 620px;
        max-width: 700px;
        margin-left: 56px;
        position: relative;

        .leftspan {
          // position: absolute;
          // top: 2px;
          // top: 0;
          // left: 126px;
          margin-left: 26px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: var(--fontColor, #fb6638);
          cursor: pointer;
        }

        .rightspan {
          display: inline-block;
          width: 130px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        .bottomp {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 6px;
        }
      }

      img {
        width: 49px;
        height: 53px;
      }
    }

    p {
      margin-top: 27px;
    }

    .setup_edit {
      width: 28px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}
</style>